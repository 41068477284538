import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaCaretDown, FaEdit, FaSignOutAlt } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';

function Navbar() {
  const { user, logout } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setDropdownOpen(false);
  };

  const handleLogout = () => {
    logout();
    closeDrawer();
    navigate('/');
  };

  const scrollToSection = (sectionId) => {
    navigate('/');
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
    closeDrawer();
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-content">
          {isMobile && (
            <button className="menu-toggle" onClick={toggleDrawer}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          )}

          <div className="logo">
            <Link to="/">Your Logo</Link>
          </div>

          {!isMobile && (
            <div className="desktop-nav">
              <ul>
                <li><a href="#home" onClick={(e) => { e.preventDefault(); scrollToSection('home'); }}>Acasă</a></li>
                <li><a href="#conference" onClick={(e) => { e.preventDefault(); scrollToSection('conference'); }}>Conferințe</a></li>
                <li><a href="#biblio" onClick={(e) => { e.preventDefault(); scrollToSection('biblio'); }}>Bibliotecă</a></li>
                <li><a href="#about" onClick={(e) => { e.preventDefault(); scrollToSection('about'); }}>Despre</a></li>
                <li><a href="#gallery" onClick={(e) => { e.preventDefault(); scrollToSection('gallery'); }}>Galerie</a></li>
                <li><a href="#contact" onClick={(e) => { e.preventDefault(); scrollToSection('contact'); }}>Contact</a></li>
                <li><Link to="/film">Film</Link></li>
                <li className="user-menu">
                  {user ? (
                    <div className="user-profile" onClick={() => setDropdownOpen(!dropdownOpen)}>
                      <FaUser style={{ color: 'white', fontSize: '1.2rem' }} />
                      <FaCaretDown style={{ color: 'white' }} />
                      {dropdownOpen && (
                        <div className="user-dropdown">
                          <button onClick={() => navigate('/profile')}>
                            <FaEdit /> Vizualizare Profil
                          </button>
                          <button onClick={handleLogout}>
                            <FaSignOutAlt /> Logout
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link to="/login" className="login-button">Login</Link>
                  )}
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>

      {isMobile && (
        <>
          {isDrawerOpen && (
            <div className="drawer-overlay" onClick={closeDrawer} />
          )}

          <div className={`side-drawer ${isDrawerOpen ? 'open' : ''}`}>
            <div className="drawer-user-section">
              {user ? (
                <>
                  <div
                    className="drawer-user-profile"
                    onClick={() => setDropdownOpen(!dropdownOpen)} // Move click handler to entire profile area
                  >
                    <FaUser size={24} />
                    <div className="drawer-user-info">
                      <p className="drawer-user-name">{user.username}</p>
                      <FaCaretDown
                        style={{
                          transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s ease'
                        }}
                      />
                    </div>
                  </div>
                  {dropdownOpen && (
                    <div className="drawer-dropdown">
                      <button onClick={() => { navigate('/profile'); closeDrawer(); }}>
                        <FaEdit style={{ marginRight: '8px' }} /> Edit Profile
                      </button>
                      <button onClick={handleLogout}>
                        <FaSignOutAlt style={{ marginRight: '8px' }} /> Logout
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <Link to="/login" className="drawer-login-button" onClick={closeDrawer}>
                  <FaUser /> Login
                </Link>
              )}
            </div>

            <div className="drawer-nav-links">
              <a href="#home" className="drawer-nav-link"
                onClick={(e) => { e.preventDefault(); scrollToSection('home'); }}>
                Home
              </a>
              <a href="#conference" className="drawer-nav-link"
                onClick={(e) => { e.preventDefault(); scrollToSection('conference'); }}>
                Conference
              </a>
              <a href="#biblio" className="drawer-nav-link"
                onClick={(e) => { e.preventDefault(); scrollToSection('biblio'); }}>
                Bibliotecă
              </a>
              <a href="#about" className="drawer-nav-link"
                onClick={(e) => { e.preventDefault(); scrollToSection('about'); }}>
                About
              </a>
              <a href="#gallery" className="drawer-nav-link"
                onClick={(e) => { e.preventDefault(); scrollToSection('gallery'); }}>
                Gallery
              </a>
              <a href="#contact" className="drawer-nav-link"
                onClick={(e) => { e.preventDefault(); scrollToSection('contact'); }}>
                Contact
              </a>
              <Link to="/film" className="drawer-nav-link" onClick={closeDrawer}>
                Film
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;