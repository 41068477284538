import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

function Conference() {
  const { user } = useAuth();
  const [conference, setConference] = useState(null);
  const [meetingLink, setMeetingLink] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchConference();
  }, []);

  const fetchConference = async () => {
    try {
      const response = await axios.get('https://luchianbe.onrender.com/conference', {
        withCredentials: true
      });
      console.log(response.data)
      setConference(response.data[0]);
    } catch (error) {
      console.error('Error fetching meeting link:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.patch(`https://luchianbe.onrender.com/conference/${conference._id}`,
        { meetingLink },
        { withCredentials: true }
      );
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error updating meeting link:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setError(`Failed to update meeting link: ${error.message}`);
    }
  };

  const joinZoomMeeting = () => {
    // Replace this URL with your actual Zoom meeting link
    if (conference.meetingLink) {
      console.log(conference.link);
      window.open(conference.meetingLink, "_blank");
    }
  };

  return (
    conference ?
      <div className="conference-container">
        <h2>Conferințe</h2>
        <div className="video-container">
          {meetingLink ? (
            <iframe
              src={meetingLink}
              width="100%"
              height="500"
              allow="camera; microphone; fullscreen; display-capture; autoplay"
              allowFullScreen
            ></iframe>
          ) : (
            <div className="video-placeholder">
              <p>În prezent nu există conferințe programate.</p>
            </div>
          )}
        </div>
        {/* <button onClick={joinZoomMeeting} className="zoom-button">Participă la conferință</button> */}
        {/* {user && user.role === 'admin' && (
          <form onSubmit={handleSubmit} className="admin-form">
            <input
              type="text"
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
              placeholder="Enter meeting link"
              required
            />
            <button type="submit">Update Link</button>
          </form>
        )} */}
        <div className="conference-description">
          {/* <h3>Conference Details</h3> */}
          {/* <p>{conference.details}</p> */}
          {/* ... rest of the conference details ... */}
        </div>
        <div class="past-events">
          <h3>Evenimente anterioare și apariții în presă</h3>
          {/* <p>Eveniment cultural la 2 Mai: ,,Ștefan Luchian: sursă de inspirație pentru identitatea și valorile
            naționale românești"</p> */}
          <div class="past-event-links">
            <a href="https://www.facebook.com/media/set/?vanity=MangaliaOline&set=a.1075942897225555"
              class="past-event-link">Presă</a>
            <a href="https://tomisnews.ro/evenimentul-stefan-luchian-sursa-de-inspiratie-pentru-identitatea-si-valorile-nationale-romanesti-la-2-mai/"
              class="past-event-link">Presă</a>
            <a href="https://focuspress.ro/evenimentul-stefan-luchian-sursa-de-inspiratie-pentru-identitatea-si-valorile-nationale-romanesti-la-2-mai/"
              class="past-event-link">Presă</a>
            <a href="https://www.replicaonline.ro/evenimentul-bdquo-stefan-luchian-sursa-de-inspiratie-pentru-identitatea-si-valorile-nationale-romanesti-rdquo-la-2-mai-606845"
              class="past-event-link">Presă</a>
            <a href="https://primarialimanu.ro/eveniment/stefan-luchian-sursa-de-inspiratie-pentru-identitatea-si-valorile-nationale-romanesti"
              class="past-event-link">Presă</a>
              <a href="https://www.replicaonline.ro/evenimentul-bdquo-stefan-luchian-sursa-de-inspiratie-pentru-identitatea-si-valorile-nationale-romanesti"
              class="past-event-link">Presă</a>
              <a href="https://uzpr.ro/18/10/2024/stefan-luchian-in-dobrogea-intr-un-proiect-cultural-despre-identitatea-romaneasca/"
              class="past-event-link">Presă</a>
              <a href="https://constantaveche.ro/evenimentul-stefan-luchian-sursa-de-inspiratie-pentru-identitatea-si-valorile-nationale-romanesti-la-2-mai/"
              class="past-event-link">Presă</a>
              <a href="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fculturalaliman%2Fposts%2Fpfbid034od5vFdqt5fQgAKmnDXFmXVUstvV3nLm8FstPztUGoCVCz4DMnGE6dUvQZFBebjFl&show_text=true&width=500"
              class="past-event-link">Presă</a>
          </div>
        </div>
      </div> : <div>Loading...</div>
  );
}

export default Conference;