import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import Login from './components/Login';
import Profile from './components/Profile';
import Film from './components/Film';
import Conference from './components/Conference';
import Biblio from './components/Biblio';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/film" element={<Film />} />
            <Route path="/conference" element={<PrivateRoute><Conference /></PrivateRoute>} />
            <Route path="/biblio" element={<PrivateRoute><Biblio /></PrivateRoute>} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
