import React, { useState } from 'react';
import ImageModal from './ImageModal';
import pdf1 from '../data/Material Educational.pdf'; 
import pdf2 from '../data/Prezentare SL.pdf'; 

function Biblio() {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);


  const biblioImages = [
    {
      id: 1,
      src: '/images/Galerie/pic6.png',
      title: 'Ștefan Luchian - Safta Florareasa (1895), ulei pe pânză',
      description: (
        <>
          <a href="https://ro.m.wikipedia.org/wiki/Fi%C8%99ier:Stefan_Luchian_-_Safta_Florareasa_(1895).jpg">Sursa foto</a>
          <p>Figura Saftei se compune din conturiri și tonalități de lumină.</p>
          <p> Fața sobră este accentuată de desen, culorile sunt așternute plat, fără modeleu, farmecul acestei lucrări fiind dat de prevalentele calități grafice.</p>
        </>
      )
    },
    { 
      id: 2, 
      src: '/images/Galerie/pic1.png', 
      title: 'Ștefan Luchian - Mos Nicolae cobzarul, 1906', 
      description: (
        <>
          <a href="https://ro.wikipedia.org/wiki/Fi%C8%99ier:Stefan_Luchian_-_Mos_Nicolae_cobzarul_(1).jpg">Sursa foto</a>
          <p> </p>
        </>
      ) },
    { id: 3, src: '/images/Galerie/pic2.png', title: 'Ștefan Luchian - Portretul doamnei Olivier, pastel pe pânză, intre 1907 si 1909', description: (
        <>
          <a href="https://ro.m.wikipedia.org/wiki/Fi%C8%99ier:Stefan_Luchian_-_Portretul_doamnei_Olivier.jpg">Sursa foto</a>
          <p> </p>
        </>
      ) },
    { id: 4, src: '/images/Galerie/pic3.png', title: 'Ștefan Luchian - Toamna', description: (
        <>
          <a href="https://www.revistabiz.ro/cea-mai-valoroasa-opera-semnata-stefan-luchian-scoasa-la-licitatie">Sursa foto</a>
          <p>”Toamna”, operă de mari dimensiuni semnată de celebrul pictor care a modernizat pictura românească. Opera realizată de Luchian în 1901, inspirat de grafica în stil Art Nouveau a contemporanului său, artistul francez Georges des Feure, face parte dintr-un ansamblu de patru uleiuri, reprezentând alegorii feminine, destinate să decoreze interioarele casei avocatului și omului politic liberal Victor Antonescu, fost ministru de justiție și apoi de finanțe în guvernul Gheorghe Tătărăscu, în colecția căruia rămâne până în 1965.</p>
        </>
      ) },
    { id: 5, src: '/images/Galerie/pic4.png', title: 'Ștefan Luchian - Garoafe, ulei pe carton,', description: (
        <>
          <a href="https://ro.m.wikipedia.org/wiki/Fi%C8%99ier:Stefan_Luchian_-_Garoafe.jpg">Sursa foto</a>
          <p>Peisaje ca cele de la Brebu sau din lunca Ialomiţei și, mai ales, popularele sale naturi statice cu flori („Garoafe”, „Trandafiri”, „Albăstrele”, „Flori de camp”) exemplifică forța incredibilă pe care Luchian a imprimat-o pastelului, picturalitatea sa intensă, vigoarea cromatică dar și spontaneitatea sa.</p>
        </>
      ) },
    { id: 6, src: '/images/Galerie/pic5.png', title: 'Ștefan Luchian - Dupa ploaie la Baneasa', description: (
        <>
          <a href="https://ro.m.wikipedia.org/wiki/Fi%C8%99ier:Stefan_Luchian_-_Dupa_ploaie_la_Baneasa.jpg">Sursa foto</a>
          
          <p> Luchian a expus în 1902, la Tinerimea Artistică, opt tablouri, între care capodopera „După ploaie la Băneasa”, iar în anul 1903 a deschis o expoziţie la Casa Assan, în care a prezentat lucrarea.</p>
      </>
      ) },
  ];

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => 
      (prevIndex + 1) % biblioImages.length
    );
  };

  const prevImage = () => {
    setSelectedImageIndex((prevIndex) => 
      (prevIndex - 1 + biblioImages.length) % biblioImages.length
    );
  };

  return (
    <div className="biblio-container">
      <h2>Bibliotecă</h2>
      <p>Opere realizate de Ștefan Luchian</p>
      <div className="biblio-images">
        {biblioImages.map((image, index) => (
          <div key={image.id} className="biblio-image-container">
            <img
              src={image.src}
              alt={image.title}
              onClick={() => openModal(index)}
            />
          </div>
        ))}
      </div>

      {/* New Section for Educational Materials */}
      <div className="educational-materials">
        <h3>Materiale educaționale</h3>
        <div className="material-buttons">
          <a href={pdf1} target="_blank" rel="noopener noreferrer" className="material-button">
            <img src="../images/EducativThumb.png" alt="Material 1" />
            <span>Descarcă Material 1</span>
          </a>
          <a href={pdf2} target="_blank" rel="noopener noreferrer" className="material-button">
            <img src="../images/PrezentareThumb.png" alt="Material 2" />
            <span>Descarcă Material 2</span>
          </a>
        </div>
      </div>

      {selectedImageIndex !== null && (
        <ImageModal
          image={biblioImages[selectedImageIndex]}
          onClose={closeModal}
          onNext={nextImage}
          onPrev={prevImage}
        />
      )}
    </div>
  );
}

export default Biblio;