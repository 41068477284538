export default [
  { value: 'AB', label: 'Alba' },
  { value: 'AR', label: 'Arad' },
  { value: 'AG', label: 'Argeș' },
  { value: 'BC', label: 'Bacău' },
  { value: 'BH', label: 'Bihor' },
  { value: 'BN', label: 'Bistrița-Năsăud' },
  { value: 'BT', label: 'Botoșani' },
  { value: 'BV', label: 'Brașov' },
  { value: 'BR', label: 'Brăila' },
  { value: 'B', label: 'București' },
  { value: 'BZ', label: 'Buzău' },
  { value: 'CS', label: 'Caraș-Severin' },
  { value: 'CL', label: 'Călărași' },
  { value: 'CJ', label: 'Cluj' },
  { value: 'CT', label: 'Constanța' },
  { value: 'CV', label: 'Covasna' },
  { value: 'DB', label: 'Dâmbovița' },
  { value: 'DJ', label: 'Dolj' },
  { value: 'GL', label: 'Galați' },
  { value: 'GR', label: 'Giurgiu' },
  { value: 'GJ', label: 'Gorj' },
  { value: 'HR', label: 'Harghita' },
  { value: 'HD', label: 'Hunedoara' },
  { value: 'IL', label: 'Ialomița' },
  { value: 'IS', label: 'Iași' },
  { value: 'IF', label: 'Ilfov' },
  { value: 'MM', label: 'Maramureș' },
  { value: 'MH', label: 'Mehedinți' },
  { value: 'MS', label: 'Mureș' },
  { value: 'NT', label: 'Neamț' },
  { value: 'OT', label: 'Olt' },
  { value: 'PH', label: 'Prahova' },
  { value: 'SM', label: 'Satu Mare' },
  { value: 'SJ', label: 'Sălaj' },
  { value: 'SB', label: 'Sibiu' },
  { value: 'SV', label: 'Suceava' },
  { value: 'TR', label: 'Teleorman' },
  { value: 'TM', label: 'Timiș' },
  { value: 'TL', label: 'Tulcea' },
  { value: 'VS', label: 'Vaslui' },
  { value: 'VL', label: 'Vâlcea' },
  { value: 'VN', label: 'Vrancea' }
]; 