import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ImageModal from './ImageModal';
import { FaEnvelope, FaFacebookSquare } from 'react-icons/fa';


function MainContent() {
  const { user } = useAuth();
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const images = [
    { id: 1, src: '/images/FlyerFata.png', title: 'Image 1', description: '' },
    // { id: 2, src: 'https://via.placeholder.com/300x200?text=Image2', title: 'Image 2', description: 'DESCRIEREA IMAGINII/OPEREI 2.' },
    // { id: 3, src: 'https://via.placeholder.com/300x200?text=Image3', title: 'Image 3', description: 'DESCRIEREA IMAGINII/OPEREI 3.' },
    // { id: 4, src: 'https://via.placeholder.com/300x200?text=Image4', title: 'Image 4', description: 'DESCRIEREA IMAGINII/OPEREI 4.' },
    // { id: 5, src: 'https://via.placeholder.com/300x200?text=Image5', title: 'Image 5', description: 'DESCRIEREA IMAGINII/OPEREI 5.' },
    // { id: 6, src: 'https://via.placeholder.com/300x200?text=Image6', title: 'Image 6', description: 'DESCRIEREA IMAGINII/OPEREI 6.' },
  ];

  const aboutImages = [
    { id: 'about1', src: '/images/FlyerFata.png', title: 'Flyer', description: '' },
    { id: 'about2', src: '/images/FlyerVerso.png', title: 'Pictorul Identității Românești', description: '' },
  ];
  return (
    <main>
      <section id="home" className="home-section">
        <div className="home-content">
          <h1>Ștefan Luchian</h1>
          <h2>Sursă de Inspirație pentru Identitatea și Valorile Naționale Românești</h2>
          <p className="project-funding">
            Proiect finanțat cu sprijinul Secretariatului General al Guvernului, prin Serviciul Dezvoltare Comunitară.
          </p>
          <div className="home-images">
            <div className="logo-container">
              <img src="/images/GuvernulRomaniei.svg" alt="Guvernul României" className="home-logo" />
            </div>
            <div className="logo-container">
              <img src="/images/S_balkanW.png" alt="Balkan Expert Grup" className="home-logo" />
            </div>
          </div>
        </div>
      </section>

      <section id="about">
        <h2>Despre Proiect</h2>
        <div className="about-content">
          <p>
            Asociația BALKAN EXPERT GRUP, organizație non-guvernamentală, non profit, este promotoarea proiectului 
            "Ștefan Luchian: Sursă de Inspirație pentru Identitatea și Valorile Naționale Românești".
          </p>
          <p>
            Scopul proiectului este promovarea diversității culturale și consolidarea legăturilor interculturale 
            prin evocarea personalității lui Ștefan Luchian în cadrul diverselor comunități, inclusiv în rândul 
            comunităților cu populație semnificativă aparținând altor etnii.
          </p>
          <p>
            Prin explorarea operei și vieții acestui remarcabil pictor român, proiectul își propune să inspire 
            înțelegerea și aprecierea artei și culturii românești într-un cadru intercultural. De asemenea, 
            proiectul urmărește să stimuleze dialogul și schimbul cultural între diferitele etnii, să încurajeze 
            exprimarea artistică și creativitatea în comunități diverse și să contribuie la construirea unei 
            societăți mai incluzive și mai armonioase, bazată pe respect reciproc și înțelegere culturală.
          </p>
        </div>
        <div className="flyer-images">
          {aboutImages.map((image) => (
            <div key={image.id} className="flyer-image-container">
              <img
                src={image.src}
                alt={image.title}
                onClick={() => setSelectedImage(image)}
              />
            </div>
          ))}
        </div>
      </section>

      <section id="conference">
        <h2>Conferințe</h2>
        <div className="conference-content">
          <p>Informații despre conferințele noastre.</p>
          <div className="button-container">
            {user ? (
              <Link to="/conference" className="join-button">Participă la conferință</Link>
            ) : (
              <>
                <p>Pentru a participa la conferință este necesar să fiți înregistrat.</p>
                <button onClick={handleLoginClick} className="login-button">Login pentru acces la Conferință</button>
              </>
            )}
          </div>
        </div>
      </section>

      <section id="biblio">
        <h2>Bibliotecă</h2>
        <div className="biblio-content">
          <p>
            Ştefan Luchian (1868-1916) a realizat de-a lungul vieții zeci de pasteluri, acuarele şi desene ce 
            reconstituie cronologic și pe tehnici parcursul și contribuția sa la dezvoltarea graficii autohtone 
            într-o perioadă de descoperire și definire a modernității românești.
          </p>
          <p>
            Unele dintre cele mai populare lucrări ale artistului sunt: „Safta florăreasa", „Portretul lui Moş 
            Niculae cobzarul", „Portretul doamnei Olivier".
          </p>
          <div className="button-container">
            {user ? (
              <Link to="/biblio" className="join-button">Vezi Biblioteca</Link>
            ) : (
              <>
                <p>Pentru a vizualiza Biblioteca este necesar să fiți înregistrat.</p>
                <button onClick={handleLoginClick} className="login-button">Login pentru acces la Bibliotecă</button>
              </>
            )}
          </div>
        </div>
      </section>

      <section id="gallery">
        <h2>Galerie</h2>
        <div className="galleryfl-images">
          {images.map((image) => (
            <div key={image.id} className="galleryfl-image-container">
              <img
                src={image.src}
                alt={image.title}
                onClick={() => setSelectedImage(image)}
              />
            </div>
          ))}
        </div>
      </section>

      {selectedImage && (
        <ImageModal
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}

      <section id="contact">
        <h2>Contact</h2>
        <div className="contact-container">
          <div className="contact-info">
            <div className="contact-item email">
              <FaEnvelope className="contact-icon" />
              <a href="mailto:spiritnational.art@gmail.com">spiritnational.art@gmail.com</a>
            </div>
            <div className="contact-item facebook">
              <FaFacebookSquare className="contact-icon" />
              <a href="https://www.facebook.com/profile.php?id=61566792066915" target="_blank" rel="noopener noreferrer">Facebook Page</a>
            </div>
          </div>
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2713.5518055791335!2d27.579357676729952!3d47.147046219598515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafba41aa5f727%3A0x5cb37ad4e759bd89!2sStrada%20Nicolina%2064%2C%20Ia%C8%99i%20700220!5e0!3m2!1sen!2sro!4v1729237478523!5m2!1sen!2sro"
              width="100%"
              height="200"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <p>Str. Nicolina 64, Iași</p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MainContent;
