import React from 'react';
import { useAuth } from '../context/AuthContext';

function Profile() {
  const { user } = useAuth();

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-container">
      <h2>Profil</h2>
      <img src={user.profilePicture || 'https://via.placeholder.com/150'} alt="Profile" className="profile-picture" />
      <div className="profile-info">
        <p><strong>Username:</strong> {user.username}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Nume:</strong> {user.firstName}</p>
        {/* <p><strong>User Type:</strong> {user.role === 'admin' ? 'Admin' : 'User'}</p> */}
        {/* {user.phoneNumber && <p><strong>Phone Number:</strong> {user.phoneNumber}</p>} */}
      </div>
    </div>
  );
}

export default Profile;