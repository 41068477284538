import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Select from 'react-select';
import romanianCounties from '../data/counties';
import romanianCities from '../data/cities';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [county, setCounty] = useState(null);
  const [city, setCity] = useState(null);
  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const navigate = useNavigate();
  const { login, register } = useAuth();

  // Add validation functions
  const validateEmail = (email) => {
    return /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
  };

  const validatePhoneNumber = (phone) => {
    return /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/.test(phone);
  };

  const validateUsername = (username) => {
    return /^[a-zA-Z0-9._]{3,30}$/.test(username);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!isLogin) {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      
      if (password.length < 6) {
        setError('Password must be at least 6 characters long');
        return;
      }

      if (!validateEmail(email)) {
        setError('Please enter a valid email address');
        return;
      }

      if (!validatePhoneNumber(phoneNumber)) {
        setError('Please enter a valid Romanian phone number');
        return;
      }

      if (!firstName || firstName.length < 2 || firstName.length > 50) {
        setError('First name must be between 2 and 50 characters');
        return;
      }

      if (!lastName || lastName.length < 2 || lastName.length > 50) {
        setError('Last name must be between 2 and 50 characters');
        return;
      }

      if (!county || !city) {
        setError('Please select both county and city');
        return;
      }

      if (!birthDay || !birthMonth || !birthYear) {
        setError('Please select your complete date of birth');
        return;
      }

      const birthDate = new Date(birthYear.value, birthMonth.value - 1, birthDay.value);
      if (birthDate >= new Date() || birthDate < new Date('1900-01-01')) {
        setError('Please enter a valid date of birth');
        return;
      }

      if (!agreeToTerms) {
        setError('You must agree to the terms and conditions');
        return;
      }

      if (!validateUsername(username)) {
        setError('Username must be 3-30 characters long and can only contain letters, numbers, dots, and underscores');
        return;
      }

      try {
        const userData = {
          username: username.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.toLowerCase().trim(),
          password,
          phoneNumber,
          county: county.value,
          city: city.value,
          DOB: birthDate,
          GDPRApproved: agreeToTerms
        };
        await register(userData);
        navigate('/');
      } catch (error) {
        if (error.response?.data?.message?.includes('duplicate key')) {
          if (error.response.data.message.includes('username')) {
            setError('This username is already taken');
          } else if (error.response.data.message.includes('email')) {
            setError('This email is already registered');
          }
        } else {
          setError(error.response?.data?.message || 'An error occurred during registration');
        }
      }
    } else {
      try {
        await login({ emailOrUsername: email, password });
        navigate('/');
      } catch (error) {
        setError(error.response?.data?.message || 'Invalid credentials');
      }
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setError('');
  };

  const handleForgotPassword = () => {
    console.log('Forgot password logic here');
    // Implement forgot password functionality
  };

  // Generate arrays for date dropdowns
  const days = Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: i + 1 }));
  const months = [
    { value: 1, label: 'Ianuarie' }, { value: 2, label: 'Februarie' },
    { value: 3, label: 'Martie' }, { value: 4, label: 'Aprilie' },
    { value: 5, label: 'Mai' }, { value: 6, label: 'Iunie' },
    { value: 7, label: 'Iulie' }, { value: 8, label: 'August' },
    { value: 9, label: 'Septembrie' }, { value: 10, label: 'Octombrie' },
    { value: 11, label: 'Noiembrie' }, { value: 12, label: 'Decembrie' }
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => ({
    value: currentYear - i,
    label: currentYear - i
  }));

  return (
    <div className="auth-container">
      <h2>{isLogin ? 'Login' : 'Create an Account'}</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit}>
        {!isLogin && (
          <>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="input-field"
            />
            <input
              type="text"
              placeholder="Nume"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="input-field"
            />
            <input
              type="text"
              placeholder="Prenume"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="input-field"
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
            <input
              type="password"
              placeholder="Parolă"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
            />
            <input
              type="password"
              placeholder="Confirmare Parolă"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="input-field"
            />
            <input
              type="tel"
              placeholder="Telefon"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              className="input-field"
            />
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Select
                placeholder="Județ"
                options={romanianCounties}
                value={county}
                onChange={setCounty}
                className="react-select-container inline-select"
                classNamePrefix="react-select"
              />
              <Select
                placeholder="Oraș"
                options={romanianCities[county?.value] || []}
                value={city}
                onChange={setCity}
                isDisabled={!county}
                className="react-select-container inline-select"
                classNamePrefix="react-select"
              />
            </div>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Select
                placeholder="Zi"
                options={days}
                value={birthDay}
                onChange={setBirthDay}
                className="react-select-container inline-select"
                classNamePrefix="react-select"
              />
              <Select
                placeholder="Lună"
                options={months}
                value={birthMonth}
                onChange={setBirthMonth}
                className="react-select-container inline-select"
                classNamePrefix="react-select"
              />
              <Select
                placeholder="An"
                options={years}
                value={birthYear}
                onChange={setBirthYear}
                className="react-select-container inline-select"
                classNamePrefix="react-select"
              />
            </div>
            <div className="terms-container">
              <input
                type="checkbox"
                id="terms"
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
                required
              />
              <label htmlFor="terms">
                <span>Sunt de acord ca datele mele sa fie folosite pentru a primi informații legate de activitatile proiectului </span>
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  Ștefan Luchian: Sursă de Inspirație pentru Identitatea și Valorile Naționale Românești
                </a>
              </label>
            </div>
          </>
        )}
        {isLogin && (
          <>
            <input
              type="text"
              placeholder="Email sau Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Parolă"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </>
        )}
        <button type="submit" className="submit-button">
          {isLogin ? 'Login' : 'Register'}
        </button>
      </form>
      <button onClick={toggleForm} className="toggle-form-button">
        {isLogin ? 'Click pentru a te înregistra' : 'Ai deja un cont? Click pentru logare!'}
      </button>
    </div>
  );
}

export default Login;