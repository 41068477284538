import React from 'react';

function Film() {
  return (
    <div className="film-container">
      <h2>Ștefan Luchian</h2>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/bm30Yk1e_bM?si=T5Kgt-Zz4gIpjWdR"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="film-description">
        <h3>Despre film</h3>
        <p>Filmul biografic „Ștefan Luchian”, regizat de Nicolae Mărgineanu și lansat în 1981, ilustrează cinematografic viața unuia dintre cei mai importanți pictori români, prezentând etape definitorii din viața sa începută spectaculos sub semnul succesului și al faimei.
          O moștenire generoasă îi oferă lui Ștefan Luchian stabilitatea financiară, eliberându-l de grijile zilnice. Sociabil și plin de viață, el alege să trăiască o existență poate mai superficială, dar plină de strălucire. Campion al primei curse de velocipede Giurgiu-București și nelipsit de la evenimentele mondene precum "bătăile cu flori" de la Șosea, Luchian frecventează cafeneaua Fialkovsky, locul preferat de întâlnire al artiștilor vremii, și pare favoritul destinului. Chiar și provocările profesionale par să-l avantajeze. După ce este respins de Salonul oficial de pictură, organizează alături de alți tineri artiști o expoziție a "Independenților", care, deși controversată, le aduce succes.
          Totuși, acest vis de glorie se transformă rapid într-un coșmar. Averea se risipește, iar Luchian este nevoit să-și câștige traiul cântând la flaut în orchestra Teatrului Național. Curând apar și primele semne ale bolii devastatoare care îl va paraliza treptat, marcând începutul sfârșitului. Iubita îl părăsește, iar prietenii se îndepărtează, lăsându-l singur cu arta sa. Pictura devine unica lui alinare, și, în ciuda dificultăților tot mai mari, își dedică ultimele forțe creației. Primele semne ale recunoașterii artistice apar, dar boala progresează, iar Luchian ajunge să picteze cu penelul legat de braț. Florile devin singurul său subiect, iar astfel iau naștere lucrări precum "Anemonele".
          Înainte ca viața sa, consumată rapid ca o flacără, să se stingă, Luchian primește vizita lui George Enescu, care îi cântă la vioară într-o noapte, aducându-i un omagiu emoționant. Această întâlnire simbolică încheie destinul său într-un mod profund și plin de reverență.
        </p>
      </div>
    </div>
  );
}

export default Film;