// import React, { createContext, useState, useContext } from 'react';
// import axios from 'axios';

// // Create a context for our authentication state
// const AuthContext = createContext(null);

// // Create a provider component
// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);

//   // Login function
//   const login = async (userData) => {
//     try {
//         await axios.post('https://luchianbe.onrender.com/auth/login', userData);
//         const response = await axios.get(`https://luchianbe.onrender.com/users/getByEmail/${userData.email}`);
//         setUser(response.data);
//     } catch (error) {
//         console.error('Login failed:', error);
//     }
//   };

//   // Logout function
//   const logout = async () => {
//     try {
//         await axios.post('https://luchianbe.onrender.com/auth/logout');
//         setUser(null);
//     } catch (error) {
//         console.error('Logout failed:', error);
//     }
//   };

//   // Provide the authentication state and functions to children
//   return (
//     <AuthContext.Provider value={{ user, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// // Custom hook to use the auth context
// export const useAuth = () => useContext(AuthContext);

import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const getUser = async () => {
    if (user === null) {
      const userResponse = await axios.get(`https://luchianbe.onrender.com/users`, {
        withCredentials: true
      })
      setUser(userResponse.data)
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const login = async (userData) => {
    try {
      const response = await axios.post('https://luchianbe.onrender.com/auth/login', userData, {
        withCredentials: true
      });
      const { token } = response.data;
      localStorage.setItem('token', token);
      const userResponse = await axios.get(`https://luchianbe.onrender.com/users/getByEmail/${userData.emailOrUsername}`);
      setUser({...userResponse.data, role: userResponse.data.role}); // Include role in user state
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const register = async (userData) => {
    try {
      const response = await axios.post('https://luchianbe.onrender.com/auth/register', userData, {
        withCredentials: true
      });
      const { token } = response.data;
      localStorage.setItem('token', token);
      const userResponse = await axios.get(`https://luchianbe.onrender.com/users/getByEmail/${userData.email}`);
      setUser(userResponse.data);
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post('https://luchianbe.onrender.com/auth/logout');
      localStorage.removeItem('token');
      setUser(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);