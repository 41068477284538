export default {
  'AB': [
    { value: 'alba-iulia', label: 'Alba Iulia' },
    { value: 'aiud', label: 'Aiud' },
    { value: 'blaj', label: 'Blaj' },
    { value: 'campeni', label: 'Câmpeni' },
    { value: 'cugir', label: 'Cugir' },
    { value: 'ocna-mures', label: 'Ocna Mureș' },
    { value: 'sebes', label: 'Sebeș' },
    { value: 'teius', label: 'Teiuș' },
    { value: 'zlatna', label: 'Zlatna' }
  ],
  'AR': [
    { value: 'arad', label: 'Arad' },
    { value: 'chisineu-cris', label: 'Chișineu-Criș' },
    { value: 'curtici', label: 'Curtici' },
    { value: 'ineu', label: 'Ineu' },
    { value: 'lipova', label: 'Lipova' },
    { value: 'nadlac', label: 'Nădlac' },
    { value: 'pancota', label: 'Pâncota' },
    { value: 'pecica', label: 'Pecica' },
    { value: 'santana', label: 'Sântana' },
    { value: 'sebis', label: 'Sebiș' }
  ],
  'AG': [
    { value: 'pitesti', label: 'Pitești' },
    { value: 'campulung', label: 'Câmpulung' },
    { value: 'curtea-de-arges', label: 'Curtea de Argeș' },
    { value: 'costesti', label: 'Costești' },
    { value: 'mioveni', label: 'Mioveni' },
    { value: 'stefanesti', label: 'Ștefănești' },
    { value: 'topoloveni', label: 'Topoloveni' }
  ],
  'BC': [
    { value: 'bacau', label: 'Bacău' },
    { value: 'buhusi', label: 'Buhuși' },
    { value: 'comanesti', label: 'Comănești' },
    { value: 'darmanesti', label: 'Dărmănești' },
    { value: 'moinesti', label: 'Moinești' },
    { value: 'onesti', label: 'Onești' },
    { value: 'slanic-moldova', label: 'Slănic-Moldova' },
    { value: 'targu-ocna', label: 'Târgu Ocna' }
  ],
  'BH': [
    { value: 'oradea', label: 'Oradea' },
    { value: 'alesd', label: 'Aleșd' },
    { value: 'beius', label: 'Beiuș' },
    { value: 'marghita', label: 'Marghita' },
    { value: 'nucet', label: 'Nucet' },
    { value: 'salonta', label: 'Salonta' },
    { value: 'sacueni', label: 'Săcueni' },
    { value: 'stei', label: 'Ștei' },
    { value: 'valea-lui-mihai', label: 'Valea lui Mihai' },
    { value: 'vascau', label: 'Vașcău' }
  ],
  'BN': [
    { value: 'bistrita', label: 'Bistrița' },
    { value: 'beclean', label: 'Beclean' },
    { value: 'nasaud', label: 'Năsăud' },
    { value: 'sangeorz-bai', label: 'Sângeorz-Băi' }
  ],
  'BT': [
    { value: 'botosani', label: 'Botoșani' },
    { value: 'bucecea', label: 'Bucecea' },
    { value: 'darabani', label: 'Darabani' },
    { value: 'dorohoi', label: 'Dorohoi' },
    { value: 'flamanzi', label: 'Flămânzi' },
    { value: 'saveni', label: 'Săveni' },
    { value: 'stefanesti-bt', label: 'Ștefănești' }
  ],
  'BV': [
    { value: 'brasov', label: 'Brașov' },
    { value: 'codlea', label: 'Codlea' },
    { value: 'fagaras', label: 'Făgăraș' },
    { value: 'ghimbav', label: 'Ghimbav' },
    { value: 'predeal', label: 'Predeal' },
    { value: 'rasnov', label: 'Râșnov' },
    { value: 'rupea', label: 'Rupea' },
    { value: 'sacele', label: 'Săcele' },
    { value: 'victoria', label: 'Victoria' },
    { value: 'zarnesti', label: 'Zărnești' }
  ],
  'BR': [
    { value: 'braila', label: 'Brăila' },
    { value: 'faurei', label: 'Făurei' },
    { value: 'ianca', label: 'Ianca' },
    { value: 'insuratei', label: 'Însurăței' }
  ],
  'B': [
    { value: 'bucuresti', label: 'București' }
  ],
  'BZ': [
    { value: 'buzau', label: 'Buzău' },
    { value: 'nehoiu', label: 'Nehoiu' },
    { value: 'patarlagele', label: 'Pătârlagele' },
    { value: 'pogoanele', label: 'Pogoanele' },
    { value: 'ramnicu-sarat', label: 'Râmnicu Sărat' }
  ],
  'CS': [
    { value: 'resita', label: 'Reșița' },
    { value: 'anina', label: 'Anina' },
    { value: 'baile-herculane', label: 'Băile Herculane' },
    { value: 'bocsa', label: 'Bocșa' },
    { value: 'moldova-noua', label: 'Moldova Nouă' },
    { value: 'oravita', label: 'Oravița' },
    { value: 'otelu-rosu', label: 'Oțelu Roșu' }
  ],
  'CL': [
    { value: 'calarasi', label: 'Călărași' },
    { value: 'budesti', label: 'Budești' },
    { value: 'fundulea', label: 'Fundulea' },
    { value: 'lehliu-gara', label: 'Lehliu Gară' },
    { value: 'oltenita', label: 'Oltenița' }
  ],
  'CJ': [
    { value: 'cluj-napoca', label: 'Cluj-Napoca' },
    { value: 'campia-turzii', label: 'Câmpia Turzii' },
    { value: 'dej', label: 'Dej' },
    { value: 'gherla', label: 'Gherla' },
    { value: 'huedin', label: 'Huedin' },
    { value: 'turda', label: 'Turda' }
  ],
  'CT': [
    { value: 'constanta', label: 'Constanța' },
    { value: 'cernavoda', label: 'Cernavodă' },
    { value: 'eforie', label: 'Eforie' },
    { value: 'harsova', label: 'Hârșova' },
    { value: 'mangalia', label: 'Mangalia' },
    { value: 'medgidia', label: 'Medgidia' },
    { value: 'murfatlar', label: 'Murfatlar' },
    { value: 'navodari', label: 'Năvodari' },
    { value: 'negru-voda', label: 'Negru Vodă' },
    { value: 'ovidiu', label: 'Ovidiu' },
    { value: 'techirghiol', label: 'Techirghiol' }
  ],
  'CV': [
    { value: 'sfantu-gheorghe', label: 'Sfântu Gheorghe' },
    { value: 'baraolt', label: 'Baraolt' },
    { value: 'covasna', label: 'Covasna' },
    { value: 'intorsura-buzaului', label: 'Întorsura Buzăului' },
    { value: 'targu-secuiesc', label: 'Târgu Secuiesc' }
  ],
  'DB': [
    { value: 'targoviste', label: 'Târgoviște' },
    { value: 'fieni', label: 'Fieni' },
    { value: 'gaesti', label: 'Găești' },
    { value: 'moreni', label: 'Moreni' },
    { value: 'pucioasa', label: 'Pucioasa' },
    { value: 'racari', label: 'Răcari' },
    { value: 'titu', label: 'Titu' }
  ],
  'DJ': [
    { value: 'craiova', label: 'Craiova' },
    { value: 'bailesti', label: 'Băilești' },
    { value: 'bechet', label: 'Bechet' },
    { value: 'calafat', label: 'Calafat' },
    { value: 'dabuleni', label: 'Dăbuleni' },
    { value: 'filiasi', label: 'Filiași' },
    { value: 'segarcea', label: 'Segarcea' }
  ],
  'GL': [
    { value: 'galati', label: 'Galați' },
    { value: 'beresti', label: 'Berești' },
    { value: 'targu-bujor', label: 'Târgu Bujor' },
    { value: 'tecuci', label: 'Tecuci' }
  ],
  'GR': [
    { value: 'giurgiu', label: 'Giurgiu' },
    { value: 'bolintin-vale', label: 'Bolintin-Vale' },
    { value: 'mihailesti', label: 'Mihăilești' }
  ],
  'GJ': [
    { value: 'targu-jiu', label: 'Târgu Jiu' },
    { value: 'bumbesti-jiu', label: 'Bumbești-Jiu' },
    { value: 'motru', label: 'Motru' },
    { value: 'novaci', label: 'Novaci' },
    { value: 'rovinari', label: 'Rovinari' },
    { value: 'targu-carbunesti', label: 'Târgu Cărbunești' },
    { value: 'ticleni', label: 'Țicleni' },
    { value: 'tismana', label: 'Tismana' },
    { value: 'turceni', label: 'Turceni' }
  ],
  'HR': [
    { value: 'miercurea-ciuc', label: 'Miercurea Ciuc' },
    { value: 'baile-tusnad', label: 'Băile Tușnad' },
    { value: 'balan', label: 'Bălan' },
    { value: 'borsec', label: 'Borsec' },
    { value: 'cristuru-secuiesc', label: 'Cristuru Secuiesc' },
    { value: 'gheorgheni', label: 'Gheorgheni' },
    { value: 'odorheiu-secuiesc', label: 'Odorheiu Secuiesc' },
    { value: 'toplita', label: 'Toplița' },
    { value: 'vlahita', label: 'Vlăhița' }
  ],
  'HD': [
    { value: 'deva', label: 'Deva' },
    { value: 'aninoasa', label: 'Aninoasa' },
    { value: 'brad', label: 'Brad' },
    { value: 'calan', label: 'Călan' },
    { value: 'geoagiu', label: 'Geoagiu' },
    { value: 'hateg', label: 'Hațeg' },
    { value: 'hunedoara', label: 'Hunedoara' },
    { value: 'lupeni', label: 'Lupeni' },
    { value: 'orastie', label: 'Orăștie' },
    { value: 'petrila', label: 'Petrila' },
    { value: 'petrosani', label: 'Petroșani' },
    { value: 'simeria', label: 'Simeria' },
    { value: 'uricani', label: 'Uricani' },
    { value: 'vulcan', label: 'Vulcan' }
  ],
  'IL': [
    { value: 'slobozia', label: 'Slobozia' },
    { value: 'amara', label: 'Amara' },
    { value: 'cazanesti', label: 'Căzănești' },
    { value: 'fetesti', label: 'Fetești' },
    { value: 'fierbinti-targ', label: 'Fierbinți-Târg' },
    { value: 'tandarei', label: 'Țăndărei' }
  ],
  'IS': [
    { value: 'iasi', label: 'Iași' },
    { value: 'harlau', label: 'Hârlău' },
    { value: 'pascani', label: 'Pașcani' },
    { value: 'podu-iloaiei', label: 'Podu Iloaiei' },
    { value: 'targu-frumos', label: 'Târgu Frumos' }
  ],
  'IF': [
    { value: 'bragadiru', label: 'Bragadiru' },
    { value: 'buftea', label: 'Buftea' },
    { value: 'chitila', label: 'Chitila' },
    { value: 'magurele', label: 'Măgurele' },
    { value: 'otopeni', label: 'Otopeni' },
    { value: 'pantelimon', label: 'Pantelimon' },
    { value: 'popesti-leordeni', label: 'Popești-Leordeni' },
    { value: 'voluntari', label: 'Voluntari' }
  ],
  'MM': [
    { value: 'baia-mare', label: 'Baia Mare' },
    { value: 'baia-sprie', label: 'Baia Sprie' },
    { value: 'borsa', label: 'Borșa' },
    { value: 'cavnic', label: 'Cavnic' },
    { value: 'dragomiresti', label: 'Dragomirești' },
    { value: 'salistea-de-sus', label: 'Săliștea de Sus' },
    { value: 'seini', label: 'Seini' },
    { value: 'sighetu-marmatiei', label: 'Sighetu Marmației' },
    { value: 'somcuta-mare', label: 'Șomcuta Mare' },
    { value: 'tautii-magheraus', label: 'Tăuții-Măgherăuș' },
    { value: 'targu-lapus', label: 'Târgu Lăpuș' },
    { value: 'viseu-de-sus', label: 'Vișeu de Sus' }
  ],
  'MH': [
    { value: 'drobeta-turnu-severin', label: 'Drobeta-Turnu Severin' },
    { value: 'baia-de-arama', label: 'Baia de Aramă' },
    { value: 'orsova', label: 'Orșova' },
    { value: 'strehaia', label: 'Strehaia' },
    { value: 'vanju-mare', label: 'Vânju Mare' }
  ],
  'MS': [
    { value: 'targu-mures', label: 'Târgu Mureș' },
    { value: 'iernut', label: 'Iernut' },
    { value: 'ludus', label: 'Luduș' },
    { value: 'miercurea-nirajului', label: 'Miercurea Nirajului' },
    { value: 'reghin', label: 'Reghin' },
    { value: 'sangeorgiu-de-padure', label: 'Sângeorgiu de Pădure' },
    { value: 'sarmasu', label: 'Sărmașu' },
    { value: 'sighisoara', label: 'Sighișoara' },
    { value: 'sovata', label: 'Sovata' },
    { value: 'tarnaveni', label: 'Târnăveni' },
    { value: 'ungheni', label: 'Ungheni' }
  ],
  'NT': [
    { value: 'piatra-neamt', label: 'Piatra Neamț' },
    { value: 'bicaz', label: 'Bicaz' },
    { value: 'roznov', label: 'Roznov' },
    { value: 'targu-neamt', label: 'Târgu Neamț' }
  ],
  'OT': [
    { value: 'slatina', label: 'Slatina' },
    { value: 'bals', label: 'Balș' },
    { value: 'corabia', label: 'Corabia' },
    { value: 'draganesti-olt', label: 'Drăgănești-Olt' },
    { value: 'piatra-olt', label: 'Piatra-Olt' },
    { value: 'potcoava', label: 'Potcoava' },
    { value: 'scornicesti', label: 'Scornicești' }
  ],
  'PH': [
    { value: 'ploiesti', label: 'Ploiești' },
    { value: 'azuga', label: 'Azuga' },
    { value: 'baicoi', label: 'Băicoi' },
    { value: 'boldesti-scaeni', label: 'Boldești-Scăeni' },
    { value: 'breaza', label: 'Breaza' },
    { value: 'busteni', label: 'Bușteni' },
    { value: 'campina', label: 'Câmpina' },
    { value: 'comarnic', label: 'Comarnic' },
    { value: 'mizil', label: 'Mizil' },
    { value: 'plopeni', label: 'Plopeni' },
    { value: 'sinaia', label: 'Sinaia' },
    { value: 'slanic', label: 'Slănic' },
    { value: 'urlati', label: 'Urlați' },
    { value: 'valenii-de-munte', label: 'Vălenii de Munte' }
  ],
  'SM': [
    { value: 'satu-mare', label: 'Satu Mare' },
    { value: 'ardud', label: 'Ardud' },
    { value: 'carei', label: 'Carei' },
    { value: 'livada', label: 'Livada' },
    { value: 'negresti-oas', label: 'Negrești-Oaș' },
    { value: 'tasnad', label: 'Tășnad' }
  ],
  'SJ': [
    { value: 'zalau', label: 'Zalău' },
    { value: 'cehu-silvaniei', label: 'Cehu Silvaniei' },
    { value: 'jibou', label: 'Jibou' },
    { value: 'simleu-silvaniei', label: 'Șimleu Silvaniei' }
  ],
  'SB': [
    { value: 'sibiu', label: 'Sibiu' },
    { value: 'agnita', label: 'Agnita' },
    { value: 'avrig', label: 'Avrig' },
    { value: 'cisnadie', label: 'Cisnădie' },
    { value: 'copsa-mica', label: 'Copșa Mică' },
    { value: 'dumbraveni', label: 'Dumbrăveni' },
    { value: 'medias', label: 'Mediaș' },
    { value: 'miercurea-sibiului', label: 'Miercurea Sibiului' },
    { value: 'ocna-sibiului', label: 'Ocna Sibiului' },
    { value: 'saliste', label: 'Săliște' },
    { value: 'talmaciu', label: 'Tălmaciu' }
  ],
  'SV': [
    { value: 'suceava', label: 'Suceava' },
    { value: 'brosteni', label: 'Broșteni' },
    { value: 'cajvana', label: 'Cajvana' },
    { value: 'campulung-moldovenesc', label: 'Câmpulung Moldovenesc' },
    { value: 'dolhasca', label: 'Dolhasca' },
    { value: 'falticeni', label: 'Fălticeni' },
    { value: 'frasin', label: 'Frasin' },
    { value: 'gura-humorului', label: 'Gura Humorului' },
    { value: 'liteni', label: 'Liteni' },
    { value: 'milisauti', label: 'Milișăuți' },
    { value: 'radauti', label: 'Rădăuți' },
    { value: 'salcea', label: 'Salcea' },
    { value: 'siret', label: 'Siret' },
    { value: 'solca', label: 'Solca' },
    { value: 'vicovu-de-sus', label: 'Vicovu de Sus' },
    { value: 'vatra-dornei', label: 'Vatra Dornei' }
  ],
  'TR': [
    { value: 'alexandria', label: 'Alexandria' },
    { value: 'rosiorii-de-vede', label: 'Roșiorii de Vede' },
    { value: 'turnu-magurele', label: 'Turnu Măgurele' },
    { value: 'videle', label: 'Videle' },
    { value: 'zimnicea', label: 'Zimnicea' }
  ],
  'TM': [
    { value: 'timisoara', label: 'Timișoara' },
    { value: 'buzias', label: 'Buziaș' },
    { value: 'ciacova', label: 'Ciacova' },
    { value: 'deta', label: 'Deta' },
    { value: 'faget', label: 'Făget' },
    { value: 'gataia', label: 'Gătaia' },
    { value: 'jimbolia', label: 'Jimbolia' },
    { value: 'lugoj', label: 'Lugoj' },
    { value: 'recas', label: 'Recaș' },
    { value: 'sannicolau-mare', label: 'Sânnicolau Mare' }
  ],
  'TL': [
    { value: 'tulcea', label: 'Tulcea' },
    { value: 'babadag', label: 'Babadag' },
    { value: 'isaccea', label: 'Isaccea' },
    { value: 'macin', label: 'Măcin' },
    { value: 'sulina', label: 'Sulina' }
  ],
  'VS': [
    { value: 'vaslui', label: 'Vaslui' },
    { value: 'barlad', label: 'Bârlad' },
    { value: 'husi', label: 'Huși' },
    { value: 'murgeni', label: 'Murgeni' },
    { value: 'negresti', label: 'Negrești' }
  ],
  'VL': [
    { value: 'ramnicu-valcea', label: 'Râmnicu Vâlcea' },
    { value: 'baile-govora', label: 'Băile Govora' },
    { value: 'baile-olanesti', label: 'Băile Olănești' },
    { value: 'berbesti', label: 'Berbești' },
    { value: 'brezoi', label: 'Brezoi' },
    { value: 'calimanesti', label: 'Călimănești' },
    { value: 'dragasani', label: 'Drăgășani' },
    { value: 'horezu', label: 'Horezu' },
    { value: 'ocnele-mari', label: 'Ocnele Mari' }
  ],
  'VN': [
    { value: 'focsani', label: 'Focșani' },
    { value: 'adjud', label: 'Adjud' },
    { value: 'marasesti', label: 'Mărășești' },
    { value: 'odobesti', label: 'Odobești' },
    { value: 'panciu', label: 'Panciu' }
  ]
};