import React from 'react';
import './ImageModal.css';

function ImageModal({ image, onClose, onNext, onPrev }) {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <button className="nav-button prev" onClick={onPrev}>&lt;</button>
        <button className="nav-button next" onClick={onNext}>&gt;</button>
        <div className="modal-image-container">
          <img src={image.src} alt={image.title} />
        </div>
        <div className="modal-text-container">
          <h3>{image.title}</h3>
          <div className="image-description">{image.description}</div>
        </div>
      </div>
    </div>
  );
}

export default ImageModal;
